import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { CoinFlipHistogram, CoinFlipBayesianModel, CoinFlipTable, RentDivision } from "story_components";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`Whether it's a game on the playground or a policy proposal on Capitol Hill,
debates about fairness are commonplace. But what does it mean for something to
be fair? It's a word that many of use frequently, but fewer of us (myself
included) would be able to rigorously define.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this story, I'd like to think more critically about what it means to be fair.
We'll explore a few different meanings of fairness, and by surveying the
landscape, we can start to develop a framework for thinking about fairness more
precisely. We'll see where mathematics can help in the formulation of fairness,
and where our modeling falls short. We'll also examine research that suggests we
are, in some sense, hard-wired to favor fairness.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In general, fairness can get complicated. But in some cases, it's relatively
straightforward to define and even measure. Let's start simple and begin with some
scenarios where fairness shouldn't be too difficult to pin down: games of
chance.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Fairness and Probability`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's start simple. Consider a coin flipping game. Heads, I win; tails, I lose.
Is this game fair?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Well, it depends on the coin. If the coin is equally likely to land on either
side, then neither player is advantaged, and the game is fair. But if the coin
is more likely to land on one side, most would argue that the game is unfair.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's unfair for a couple of reasons. First, one player is unreasonably
advantaged over the other (it's not as though this is a game of skill). Second,
if the coin is biased, it's not necessarily true that the disadvantaged player
will know about the deception. Otherwise, why agree to play the game?`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this case, fairness feels fairly straightforward in principle: the game is
fair if the coin is just as likely to land on one side as it is on the other.
But in practice, how can we determine whether or not the game is fair? After
all, a slight bias in the coin may be hard to detect. It's easier to agree on
the ideals than on the details. Fairness may make sense in the abstract, but how
can we ensure that the coin we use actually adheres to our sense of fairness?`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`A Frequentist Approach`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you don't know whether or not the coin is biased, there are a few ways to
avoid getting roped into an unfair game. One is a `}<MDXTag name="strong" components={components} parentName="p">{`frequentist`}</MDXTag>{` approach: we
flip the coin many times and use the proportion of flips that land on heads to
measure likelihood that the coin is biased.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Flipping the coin once tells us nothing about whether the game is fair. Flipping
it twice doesn't tell us much either: even if the coin is fair, it will land on
the same side both times 50% of the time. But what if we flip the coin 100
times? or 10,000? Or --- wait for it --- one `}<MDXTag name="em" components={components} parentName="p">{`billion`}</MDXTag>{` times?`}</MDXTag>
      <MDXTag name="p" components={components}>{`As the number of flips grows, the proportion of flips that land on any one side
begins to approximate a `}<MDXTag name="strong" components={components} parentName="p">{`normal distribution`}</MDXTag>{`, or bell curve. This means that
we can estimate the likelihood of certain outcomes by calculating the area under
that curve. `}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a demonstration of what we're getting at. Below, you can adjust the true
probability that a coin lands on heads, and then model how many times you'd like
to flip a coin. The histogram will adjust accordingly, and show you how likely
it to have a certain number of coin flips land heads up.`}</MDXTag>
      <CoinFlipHistogram caption="Figure 1: An interactive probability distribution
for flipping coins." />
      <MDXTag name="p" components={components}>{`Note that as the number of coin flips increases, the probability distribution
begins to center around the true probability of getting heads. For example,
after 100 flips, there's a roughly 95% chance that heads should have appeared
between 40 and 60 times. Similarly, there's a roughly 99% chance that it should
have appeared between 37 and 63 times. Depending on your tolerance for unlikely
events, a head tally outside of one of these two ranges should give you pause.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`A Bayesian Approach`}</MDXTag>
      <MDXTag name="p" components={components}>{`The more you flip the coin, the more confident you can be in whether it's fair
or unfair. But you'll never be able to pin down the `}<MDXTag name="em" components={components} parentName="p">{`true`}</MDXTag>{` probability of the
coin landing on heads, no matter how many trials you run. For this reason,
there's another approach worth examining: the `}<MDXTag name="strong" components={components} parentName="p">{`Bayesian`}</MDXTag>{` one.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The biggest difference with the Bayesian model is that we assume the true
likelihood of our coin landing on heads is always unknown. The best we can do,
then, is try to come up with a probability distribution for our coin, which
changes every time we flip the coin and gather new information.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In order to work with this approach, we also need a `}<MDXTag name="strong" components={components} parentName="p">{`prior distribution`}</MDXTag>{`. This
is a fancy way of saying that we need to make an assumption about how likely
with think the coin is to be fair before we've collected any data. Here we'll
consider two different priors.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In the first, we'll assume that any heads probability is equally likely: maybe
the coin is fair, maybe it's much more likely to land on heads, maybe it's much
more likely to land on tails. Who knows?`}</MDXTag>
      <MDXTag name="p" components={components}>{`For the second prior, we'll assume that the coin is much more likely to be fair
than not. If you expect that the coin hasn't been doctored in any way, this
seems like a reasonable hypothesis.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The prior you choose has a significant impact on how the model evolves. If you
assume any outcome is equally likely, a string of consecutive heads will
strongly suggest bias. But if you start by assuming the coin is likely to be fair,
it takes more evidence to significantly move the probability distribution.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In the demonstration below, you can start with either prior, and then mimic as
many coin flips as you want. A nice bell shape around 50% represents a coin
that's likely to be fair, while a curve that's biased towards either side
suggests that the coin isn't balanced.`}</MDXTag>
      <CoinFlipBayesianModel caption="Figure 2: Modeling the likelihood that a coin is fair, given two possible priors." />
      <MDXTag name="p" components={components}>{`Both approaches---frequentist and Bayesian---have their tradeoffs. If you're
interested in learning more about these approaches, there's a nice article
available on the MIT `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://ocw.mit.edu/courses/mathematics/18-05-introduction-to-probability-and-statistics-spring-2014/readings/MIT18_05S14_Reading20.pdf"
        }}>{`open courseware website`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`(As a mathematical aside, the standard Bayesian model for trying to estimate the
probability of a coin landing on heads involves the Beta function. You can read more about the derivation of the above model `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://ocw.mit.edu/courses/sloan-school-of-management/15-097-prediction-machine-learning-and-statistics-spring-2012/lecture-notes/MIT15_097S12_lec15.pdf"
        }}>{`here`}</MDXTag>{`.)`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Changing the Game`}</MDXTag>
      <MDXTag name="p" components={components}>{`Regardless of your philosophy (frequentist or Bayesian), these examples show
that there are ways to assess whether or not the game is fair. It's not perfect,
but the more data you collect, the more certain you can be.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But if you're worried about the possibility of a biased coin and don't have the
opportunity to collect data, there's another solution: change the rules of the
game. In fact, there's a way to create a fair game even out of a biased coin.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Consider the following variation on the coin-flipping game, described in a 1951
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://mcnp.lanl.gov/pdf_files/nbs_vonneumann.pdf"
        }}>{`paper`}</MDXTag>{` by mathematician
John von Neumann. We have a coin, but we have no idea whether it's fair or not.
You inspect the coin and see that the two sides of it are different, so the
probability that it lands on heads is not 0 or 1.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here are the rules: flip the coin twice. If it comes up on the same side both
times, the game continues. If it comes up heads and then tails, I win. If it
comes up tails and then heads, you win.`}</MDXTag>
      <MDXTag name="p" components={components}>{`So, what are each of our chances of winning? Well, in any given round there are
four possible outcomes. If we let `}<MDXTag name="strong" components={components} parentName="p">{`H`}</MDXTag>{` represent the coin landing on heads, and
`}<MDXTag name="strong" components={components} parentName="p">{`T`}</MDXTag>{` represent the coin landing on tails, these outcomes are HH, HT, TH, and
TT. In two of these scenarios (HH and TT), the game proceeds to a new round.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But since we know the probability of landing on heads isn't 0 or 1, we also know
that `}<MDXTag name="em" components={components} parentName="p">{`eventually`}</MDXTag>{` we'll get either the HT or TH outcome. And because the coin
flips are independent---that is, because the outcome of the second flip doesn't
depend on the outcome of the first---the probabilities of these two events are
the same!`}</MDXTag>
      <CoinFlipTable caption="Figure 3: Examining the likelihood of TH and HT outcomes for different probabilities." />
      <MDXTag name="p" components={components}>{`The takeaway here is that it's not hard to take rules which seem fair and skew
them so that the game is unfair. In order to ensure agreement between the rules
and the implementation, we've seen two possible approaches. The first is
`}<MDXTag name="em" components={components} parentName="p">{`data`}</MDXTag>{`-driven: we flip the coin many times and use that data to make judgments
about whether or not the coin is biased. The second is `}<MDXTag name="em" components={components} parentName="p">{`system`}</MDXTag>{`-driven: we
change the rules of the game to remove potential sources of bias. This can be
helpful if the data we need is unavailable or prohibitively expensive to
acquire.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Fair Division`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are plenty of other scenarios in which the fairness question doesn't have
a straightforward answer.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Imagine, for instance, that you sign a lease for an apartment with two friends.
Each of you will have your own bedroom, but in order to preserve roommate
harmony, you need to decide who gets which room and how much each person pays.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Questions of fairness immediately come up. Should the rent be split evenly? What
if one room is clearly better than the others? What if one roommate has a strong
preference for a specific room, even if it isn't necessarily the best one?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Fairness quickly becomes fuzzy. After all, people prioritize different things.
You may even have conflicting desires, making it difficult to rank your
preferences in the first place. In situations like these, can we determine a
fair way to assign rooms and costs so that everyone is happy?`}</MDXTag>
      <MDXTag name="p" components={components}>{`It turns out that the we can, and the argument comes from a seemingly
unrelated result in geometry. This connection was first made explicit in a paper
titled `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.math.hmc.edu/~su/papers.dir/rent.pdf"
        }}>{`Rental Harmony: Sperner's Lemma in Fair Division`}</MDXTag>{`, by Francis Edward Su.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To understand Su's argument, it helps to work with a concrete example. Let's
suppose three roommates are trying to find a fair division of a $1,600 rent for
a three bedroom apartment. (The argument works with more roommates, but the
geometry is easiest to visualize with 3.)`}</MDXTag>
      <MDXTag name="p" components={components}>{`We need a handful of assumptions on each person's decision making. Here are the
first two (I'll save the last one until we need it):`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Everyone always prefers a free room to a non-free room.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`No matter what the division of prices, at least one room will be acceptable
to every roommate. (It's never the case that all of the rooms will be out of
a specific roommate's budget.)`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Given these two assumptions, here's how we can find a fair division of prices:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Draw a triangle. Each corner of the triangle represents a pricing scheme
where one of the rooms is the full $1,600, and the other two rooms are free.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Subdivide the triangle into a mesh of points. Each point in the mesh
corresponds to a division of the $1,600 rent for the three rooms. Prices are
based on how close the point is to the corners. For instance, a mesh point in
the middle of the triangle corresponds to each room having the same price.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Assign each point in the mesh to a roommate. How you assign the points
doesn't matter, but you want to be sure that for every small triangle formed
by the mesh, each corner is owned by a different roommate.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Go through the mesh points, and for each one, ask the owner of the point
which room they would prefer at the prices for that point.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Once you find a small triangle in the mesh where each roommate selects a
different room at the corners, you're finished! You've found a fair division
of the rent. If the differences in room prices are too large, you can refine
the mesh and play again. (You're guaranteed to find another acceptable
triangle within the triangle you've just found.)`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`The best way to appreciate this approach is to go through it. So here's an
example of the algorithm in action, with three roommates deciding between three
rooms: a green one, an orange one, and a purple one. (You can mouse over any
point to see the room prices at that point.)`}</MDXTag>
      <RentDivision captionMarginTop="-2.8em" caption="Figure 4: Determining a fair division of rent for three roommates." />
      <MDXTag name="p" components={components}>{`Here are some questions people typically ask after playing around with this
algorithm: `}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`How do you know you can find a triangle in the mesh where each roommate
selects a different room?`}</MDXTag>{` This requires proof! I'll let you fill in the
details if you're curious, but a couple of hints: first, note that along any
side of the triangle, the colors of the points are predetermined, because
exactly one of the rooms is free along each side, and people will always select
a free room over a non-free room.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The key idea in the proof is to think of each small triangle in the mesh as a
room. Play with the demonstration and see if you can figure out how it decides
which room to move to next. (This is an implementation of Su's "trap-door"
argument.)`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Is the fair collection of prices unique?`}</MDXTag>{` Not necessarily! You're actually
guaranteed to find an odd number of triangles in the mesh where every corner
corresponds to a different room. You're also allowed to traverse through the
mesh in a different way: The New York Times ran an `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.nytimes.com/interactive/2014/science/rent-division-calculator.html"
        }}>{`article about this algorithm`}</MDXTag>{`
back in 2014 using a different traversal method.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`If you take finer and finer meshes, how do you know you'll keep finding
solutions inside of older solutions?`}</MDXTag>{` Here's where Su's third assumption comes
in handy: if a roommate prefers a given room for a set of prices which converge
to some limiting price, then the roommate will still prefer that room at that
limiting price.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Intuitively, this division algorithm seems fair: the process doesn't favor one
roommate over another, and everyone winds up with an acceptable room at an
acceptable price. In game theoretic terms, this arrangement is called
`}<MDXTag name="strong" components={components} parentName="p">{`envy-free`}</MDXTag>{`, since no roommate would prefer to trade rooms with anyone else.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Beyond Mathematics: Fairness from Behind the Veil of Ignorance`}</MDXTag>
      <MDXTag name="p" components={components}>{`We've now seen several examples of math applied to questions of fairness.
However, all of them have assumed that every participant has equal say. While
this may be true when deciding where to live or whether to play a game, it's
clearly not always the case. In politics, for example, not every voice at the
table is equally strong, nor are those voices free of bias. In these more
complex cases, what does fairness mean, and how can we try to ensure it?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Rather than drawing from mathematical literature, let's address this question
from the perspective of political philosophy. One classic thought experiment
that attempts to address this question comes from John Rawls, a philosopher who
taught at Harvard University until his death in 2002. Rawls made many
contributions to the fields of moral and political philosophy during his career.
The one we'll focus on is called the `}<MDXTag name="strong" components={components} parentName="p">{`veil of ignorance.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Before we examine the veil of ignorance, let's connect it to the question of
fairness. In his book `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.amazon.com/Justice-Fairness-Restatement-John-Rawls/dp/0674005112"
        }}>{`Justice as Fairness`}</MDXTag>{`,
Rawls says, "The most fundamental idea in this conception of justice is the
idea of society as a `}<MDXTag name="em" components={components} parentName="p">{`fair`}</MDXTag>{` system of social cooperation over time from
one generation to the next" (p. 5, emphasis added).`}</MDXTag>
      <MDXTag name="p" components={components}>{`So what does fairness mean in this context? Later on, Rawls defines fair terms
of cooperation as ones that "each participant may reasonably accept, and
sometimes should accept, provided that everyone else likewise accepts them" (p.
6). Just as we've uncovered in our mathematical examples, fairness in Rawls'
writing connotes a sense of reciprocity: if an agreement can't be accepted by
all parties, this may be because it isn't fair.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But still, the question remains: what can we do to ensure that an agreement has
been entered into under fair circumstances? Rawls recognized this issue, writing
that the circumstances must "situate free and equal persons fairly and must not
permit some to have unfair bargaining advantages over others. Further, threats
of force and coercion, deception and fraud, and so on must be ruled out" (p.
15). This seems all well and good. In practice, however, ensuring that fair
agreements are entered into fairly can be quite challenging.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The solution Rawls proposes is the veil of ignorance. Here's how he introduces
it:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`[T]`}{`he parties are not allowed to know the social positions or the particular
comprehensive doctrines of the persons they represent. They also do not know
persons' race and ethnic group, sex, or various native endowments such as
strength and intelligence, all within the normal range. We express these
limits on information figuratively by saying the parties are behind a veil of
ignorance. (p. 15)`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`The idea here is to situate negotiators in such a way that they don't know
specifics about the community they are representing. The goal is for the lack of
information to minimize arguments based on self-interest.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Rawls discusses the veil of ignorance as it pertains to the creation of society,
but the same ideas can be applied to scenarios that are not as grand in scope.
(In fact, it may be more straightforward to do in simpler scenarios; trying to
apply the veil of ignorance to complex social systems can be quite challenging
in practice.) Even in the context of our coin-flipping game, the veil of
ignorance can be used to help ensure the game is played fairly.`}</MDXTag>
      <MDXTag name="p" components={components}>{`When laying out the ground rules, players who argue from the veil of ignorance
don't know beforehand who will bring the coin, who will flip the coin, who will
win if the coin lands on heads, and so on. This lack of information makes means
that both parties should be more willing to agree to rules prohibiting biased
coins. Thinking about a scenario from the veil of ignorance can help in the
development of checks and balances.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Why Fairness?`}</MDXTag>
      <MDXTag name="p" components={components}>{`As we've seen, fairness involves a certain degree of symmetry: in a fair
agreement, equal parties should have equal bargaining power. This isn't to say
that fair agreements must necessarily produce fair outcomes; after all, it's not
unfair if a football team is bested by a more prepared opponent. But it may be
unfair if the rules are applied differently to two equally-matched teams in
order to produce a winner.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Through all of this discussion, though, a question lingers: does fairness even
matter? After all, in practice, life often seems quite unfair. Instead of trying
to increase fairness, why not focus on something like utility, or something even
more concrete, like wealth?`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are at least two responses to this question, one moral, one practical. The
moral argument is that increasing fairness aligns with the idea of people being
created equal, which isn't necessarily true of other metrics. Note that fairness
does not imply everyone should be granted the same quantity of resources. But it
does align closely with equality of opportunity and in treatment by the
institutions of justice.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The practical argument is that it turns out humans care about fairness. This
fact has been borne out in a number of economics experiments, which are
summarized in a 2006 paper titled `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.sciencedirect.com/science/article/pii/S1574071406010086"
        }}>{`The economics of fairness, reciprocity and altruism--experimental evidence and new theories`}</MDXTag>{`.
Two illustrative examples come in the form of games:
the `}<MDXTag name="strong" components={components} parentName="p">{`ultimatum game`}</MDXTag>{` and the "`}<MDXTag name="strong" components={components} parentName="p">{`dictator game.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Both games involve two people and a pot of money. In the ultimatum game, one
person proposes a division of the money. The other person can then accept that
proposal or reject it. If the second person accepts, the pot is split according
to the agreement. But if the second person rejects, both people get nothing.`}</MDXTag>
      <MDXTag name="p" components={components}>{`A fair division might seem like a 50-50 split. But since the second person can't
counter the offer, a rational choice for the first person would be to offer the
smallest amount of money possible. If the pot is $100 and you propose a split of
$99.99 to $0.01, we both walk away with more money if I accept, even though the
split is so lopsided.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In practice, people rarely exhibit this sort of extreme rationality.
Experimental data shows that a majority of people offer between $40 and $50.
Similarly, offers of less than $20 are rejected somewhere between 40% and 60% of
the time. As to why these offers tend to be rejected, in the paper mentioned
above, the authors write that "In general, the motive indicated for the
rejection of positive, yet 'low', offers is that subjects view them as unfair"
(p. 622).`}</MDXTag>
      <MDXTag name="p" components={components}>{`For people on the receiving end of the offer, then, fairness seems to be a
significant factor in their decision. But what about for the folks who are
making the offer? Is fairness a factor for them as well, or are they merely
offering more than what their rational self-interest would prefer in order to
increase the likelihood that the offer is accepted?`}</MDXTag>
      <MDXTag name="p" components={components}>{`One way to answer this question is to consider an alternative to the ultimatum
game called the "dictator game." This game also involves splitting a sum of
money between two parties, but this time the first person's offer can't be
rejected. Here, the rational thing to do if you're the first person is to just
take all the money. However, "In experiments, proposers typically
dictate allocations that assign the Recipient on average between 10 and 25
percent of the surplus, with modal allocations at 50 percent and zero. These
allocations are much less than proposers' offers in ultimatum games, although
most players do offer something" (p. 622). While these offers can't be
attributed directly to an ingrained sense of fairness, altruism does appear to
play a role decision-making even when people have all of the power.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Conclusion: The Reasonable Ineffectiveness of Self-Interest in Modeling`}</MDXTag>
      <MDXTag name="p" components={components}>{`But if rational self-interest isn't our primary motivator, why do so many models
assume otherwise? One reason is that capitalism tends to breed competitive
markets. When competition is the driving factor, incentives for fair play
tend to be diminished. In other words, when it comes to competitive markets:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`[R]`}{`ational individuals will not express their other-regarding preferences in
these markets because the market makes the achievement of other-regarding
goals impossible or infinitely costly. However, a large amount of economic
activity takes place outside competitive markets – in markets with a small
number of traders, in markets with informational frictions, in firms and
organizations, and under contracts which are neither completely specified nor
enforceable. Models based on the self-interest assumption frequently make very
misleading predictions in these environments, while models of other-regarding
preferences predict much better (p. 618).`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In other words, the failure of fairness to rear its head in many economic models
is because the systems being modeled don't concern themselves with being fair!`}</MDXTag>
      <MDXTag name="p" components={components}>{`But running a business is not the same as nurturing a society or healing a
planet. While healthy competition can be helpful, it's also important for
citizens to feel they are being treated fairly, both by one another and by their
institutions.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://arxiv.org/abs/0906.0557"
            }}>{`An axiomatic theory of fairness in network resource allocation`}</MDXTag>{`, by Tian Lan, David Kao, Mung Chiang, and Ashutosh Sabharwal.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://ocw.mit.edu/courses/mathematics/18-05-introduction-to-probability-and-statistics-spring-2014/readings/MIT18_05S14_Reading20.pdf"
            }}>{`Comparison of frequentist and Bayesian inference`}</MDXTag>{`, by Jeremy Orloff and Jonathan Bloom.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.amazon.com/Justice-Fairness-Restatement-John-Rawls/dp/0674005112"
            }}>{`Justice as Fairness: A Restatement`}</MDXTag>{`, by John Rawls.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://ocw.mit.edu/courses/sloan-school-of-management/15-097-prediction-machine-learning-and-statistics-spring-2012/lecture-notes/MIT15_097S12_lec15.pdf"
            }}>{`Probabilistic Modeling and Bayesian Analysis`}</MDXTag>{`, by Ben Letham and Cynthia Rudin.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.math.hmc.edu/~su/papers.dir/rent.pdf"
            }}>{`Rental Harmony: Sperner's Lemma in Fair Division`}</MDXTag>{`, by Francis Edward Su.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.nytimes.com/2014/04/29/science/to-divide-the-rent-start-with-a-triangle.html"
            }}>{`To Divide the Rent, Start With a Triangle`}</MDXTag>{`, by Albert Sun.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.sciencedirect.com/science/article/pii/S1574071406010086"
            }}>{`The economics of fairness, reciprocity and altruism--experimental evidence and new theories`}</MDXTag>{`, by Ernst Fehr and Klaus M. Schmidt.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://mcnp.lanl.gov/pdf_files/nbs_vonneumann.pdf"
            }}>{`Various Techniques Used in Connection With Random Digits`}</MDXTag>{`, by John von Neumann.`}</MDXTag>
        </MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "The Fairest of Them All",
  "date": "2019-01-08",
  "featured_image": "../../images/featured_images/fairest_of_them_all.jpg",
  "caption": "A mathematical exploration of fairness.",
  "featured_image_caption": "Playing a (hopefully fair) board game. Image credit: Brendan Humphreys from Pexels.",
  "tags": ["probability", "civics", "modeling"]
};
      